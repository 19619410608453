<template>
  <div class="bg-cream">
    <Nav color="blue"></Nav>
    <section id="productInfo" class="padding-main">
      <div class="container-large">
        <router-link to="/shop">&#60; All Products</router-link>
        <div class="row gap-3 mt-1 space-between mobile-reset-gap">
          <div class="basis-50-gap-3 left">
            <img v-if="itemInfo.productImages[imgActive].type === 'img'" v-lazy="{src: require(`../../assets/img/product/${itemId}/${itemInfo.productImages[imgActive].path}`), loading: require(`../../assets/img/product/${itemId}/${itemInfo.productImages[imgActive].load}`)}" class="card">
            <div v-else class="card video">
              <iframe allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="card aspect-16-9" :src="itemInfo.productImages[imgActive].src"></iframe>
              <div style="width: 100%; height: 100%; background-color: transparent;z-index: 200; position: absolute"></div>
              <img v-if="player.lgPlay" id="lg-play" src="../../assets/img/player/play-large.png" @click="handlePlayerEvent()">
              <div v-else class="player-bottom row">
                <img :src="require(`../../assets/img/player/${player.icons[player.status]}`)" @click="handlePlayerEvent()">
                <div id="video-player-full" class="time-tracking" @click="getVideoTime">
                  <span id="video-player-duration" ></span>
                </div>
                <img :src="require(`../../assets/img/player/${player.volumeIcons[player.volumeIconSelect]}`)" @click="handleVolume()">
  <!--              <img :src="require(`../assets/img/player/${player.fullscreen[player.fullscreenStatus]}`)" @click="handleFullscreen()">-->
              </div>
            </div>
            <div class="grid img-list">
              <img v-for="(image, index) in itemInfo.productImages" v-lazy="{src: require(`../../assets/img/product/${itemId}/${image.path}`), loading: require(`../../assets/img/product/${itemId}/${image.load}`)}" :key="index" @click="setImageAsActive(index)" :class="index === imgActive ? 'active' : ''">
            </div>
          </div>
          <div class="right basis-50-gap-3">
            <div class="section line-break-handling row space-between">
              <div class="basis-65-gap-1">
                <h3>{{itemInfo.projectName}} <br/> <span>{{itemInfo.subtitle}}</span></h3>
              </div>
              <p class="price basis-35-gap-1">C${{itemInfo.price[currentCurrency].value}}</p>
  <!--            <p class="price">${{itemInfo.price[currentCurrency].value}}{{itemInfo.price[currentCurrency].currency}}</p>-->
            </div>
            <div class="section line-break-handling no-mb">
              <h5 class="blue mb-1">{{itemInfo.highlight}}</h5>
              <p>{{itemInfo.description}}</p>
            </div>
            <div v-if="!item.tags.includes('coming-soon')">
              <div :id='`atc_${item.productId}`' style="min-width: 100%"></div>
            </div>
            <div v-else>
              <button class="dark full disabled">Coming soon</button>
              <p v-if="item.comingSoonText" v-html="item.comingSoonText" class="mb-1"></p>
            </div>
            <div class="section">
              <h5>Details</h5>
              <ul>
                <li v-for="note in itemInfo.details" :key="note" v-html="note"> </li>
              </ul>
            </div>
            <div class="section" v-if="itemInfo.dimensions.length > 0">
              <h5>Dimensions</h5>
              <ul>
                <li v-for="note in itemInfo.dimensions" :key="note" v-html="note"> </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="item.components.length > 0" class="container-large row gap-3" >
      <component v-for="component in item.components" :key="component" :is="component"></component>
    </div>
    <Footer color="cream"></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
import Nav from "../../components/Nav.vue";
import QuantityRange from "../../components/QuantityRange.vue";
import {Icon} from '@iconify/vue';
import router from "../../router/index.js";
import TBWTTNLCards from '@/components/ProductSpecific/TBWTTNLCards.vue';
import TBWTTNLConcept from "../../components/ProductSpecific/TBWTTNLConcept.vue"

export default {
  name: 'ProductPage',
  components: { TBWTTNLCards, TBWTTNLConcept, Footer, Nav, QuantityRange, Icon},
  data() {
    return {
      imgActive: 0,
      quantity: 1,
      item: null,
      itemId: "",
      itemSlug: '',
      itemInfo: '',
      shippingOptions: '',
      errorMessage: {
        wrongQuantity: false,
        message: 'Please enter a quantity above 0 to place an order'
      },
      isAddedToCart: false,
      isAddingToCart: false,
      showGoToCart: false,
      player: {
        playerInstance: null,
        status: 0,
        lgPlay: true,
        icons: ['sm-player-play.png', 'sm-player-pause.png'],
        fullscreen: ['sm-player-fullscreen-open.png', 'sm-player-fullscreen-close.png'],
        volume: 0,
        volumeIconSelect: 0,
        volumeIcons: ['sm-player-sound-mute.png', 'sm-player-sound-medium-loud.png', 'sm-player-sound-loud.png'],
        fullscreenStatus: 0,
        duration: 0,
        interval: null
      }
    }
  },
  methods: {
    setImageAsActive(index) {
      this.imgActive = index;
    },
    updateQuantityItem(itemId, newQuantity) {
      this.quantity = newQuantity;
    },
    handlePlayerEvent() {
      let self = this;
      var iframe = document.querySelector('iframe');
      var player = new Vimeo.Player(iframe);
      if (this.player.status === 1){
        player.pause();
        this.player.status = 0;
      } else {
        player.play();
        this.player.status = 1;
        if (this.player.duration == 0) {
          player.setVolume(0);
          player.getDuration().then( (duration) => {
          self.player.duration = duration;
        })
        }
        this.player.interval = setInterval(this.playerTime, 1000);
        if (this.player.lgPlay) {
          this.player.lgPlay = false;
        }
      }
    },
    async playerTime () {
      let self = this;
      var iframe = document.querySelector('iframe');
      var player = new Vimeo.Player(iframe);
      player.getCurrentTime().then((seconds) => {
        if (seconds > self.player.duration) {
          this.player.lgPlay = true;
          player.pause()
          player.setCurrentTime(0);
          this.player.status = 0;
          clearInterval(self.player.interval)
        }
        var spanVideo = document.getElementById('video-player-duration');
        spanVideo.style.width = seconds / self.player.duration * 100 + "%";
      })
    },
    getVideoTime(event) {
      var spanVideo = document.getElementById('video-player-full');
      var percentage = event.offsetX / spanVideo.offsetWidth;
      var iframe = document.querySelector('iframe');
      var player = new Vimeo.Player(iframe);
      player.setCurrentTime(Math.floor(this.player.duration * percentage));
    },
    handleFullscreen() {
      var iframe = document.querySelector('iframe');
      if (this.player.fullscreenStatus == 0){
        // iframe.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    handleVolume() {
      var iframe = document.querySelector('iframe');
      var player = new Vimeo.Player(iframe);
      if (this.player.volumeIconSelect === 0) {
        this.player.volumeIconSelect = 1;
        this.player.volume = 0.3;
      } else if (this.player.volumeIconSelect === 1) {
        this.player.volumeIconSelect = 2;
        this.player.volume = 0.6;
      } else {
        this.player.volumeIconSelect = 0;
        this.player.volume = 0;
      }
      player.setVolume(this.player.volume);
    }
  },
  computed: {
    currentCurrency() {
      return this.$store.getters.getCurrentCurrencyIndex;
    }
  },
  watch: {

  },
  created() {
    this.item = this.$store.getters.getProductBySlug(this.$route.params.productName);
    this.itemId = this.item.itemId;
    this.itemInfo = this.item.info;
    this.shippingOptions = this.$store.getters.getShippingOptions;
    this.imgActive = this.itemInfo.thumbnailImgForProductPage;
  },
  mounted() {
    console.log(this.item.productId);
    this.$store.dispatch('createBuyButton', this.item.productId);
  },
  beforeUnmount() {
    clearInterval(this.player.interval);
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';


.line-break-handling {
  white-space: pre-line
}

#productInfo {
  position: relative;

  //.fg {
  //  padding-top: 8em;
  //  padding-bottom: 3em;
  //  width: calc(min(1440px, 90%));
  //  margin-left: 10%;
  //  gap: 2em;
  //
  //}

  .success-cart {
    align-items: center;
    justify-content: center;
    margin: 1em 0;
    border: $blue 1px solid;
    border-radius: 20px;
    padding: 0.5em 2.5em;

    p {
      color: $blue;
      margin: 0 1em;
      padding: 0;
    }
  }
}

.left {
  //flex-basis: 50%;
  margin-bottom: 3em;

  img.card, div.video {
    width: 100%;
    //TO FIX
    //aspect-ratio: 16/10;
    border-radius: 10px;
  }

  .img-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5em;
    padding-top: .5em;

    img {
      max-width: calc(100% - 2px);
      border: 2px transparent solid;
      border-radius: 10px;
      filter: drop-shadow(-1px 2px 2px rgba(0, 0, 0, 0.10));
      //aspect-ratio: 16/10;

      &.active {
        border: 2px $blue-hover solid;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .video {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16/10;
    border-radius: 20px;
    position: relative;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
      transform: scale(1.28);
      position: absolute;
      top: 10px;
    }

    #lg-play {
      position: absolute;
      z-index: 200;
      width: 20%;
      max-width: 100px;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);

      &:hover {
      cursor: pointer;
    }
    }

    .player-bottom {
      position: absolute;
      z-index: 200;
      width: 100%;
      bottom: 5px;
      left: 10px;
      align-items: center;
      gap: 10px;
      margin: auto;

      img {
        width: 30px;
        height: 30px;

        &:hover {
      cursor: pointer;
    }
      }

      .time-tracking {
        max-width: 100%;
        width: calc(100% - 60px - 20px - 20px);
        height: 8px;
        border-radius: 20px;
        background-color: $blue;

        &:hover {
      cursor: pointer;
    }
      }

      #video-player-duration {
        border-radius: 20px;
        background-color: $teal;
        height: 100%;
        display:  block;
      }
    }

  }
}

.right {
  //flex-basis: 40%;

  span {
    font-size: 0.8em;
    font-weight: 400;
    font-family: Karla, 'sans-serif';
    font-style: italic;
  }

  div.section {
    padding-bottom: 1em;

    &.no-mb {
      padding-bottom: 0;
    }
  }

  h5 {
    padding-bottom: .5em;
  }

  p.price {
    font-size: 2.5em;
    font-weight: 800;
    color: $blue;
    text-align: right;
  }

  .quantity {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
  }

  button {
    margin-top: 0;
    margin-bottom: 1.5em;
  }

  ul {
    list-style: disc;
    margin-left: 2em;

    li {
      line-height: 1.2em;
      margin-bottom: .4em;
    }
  }

  p.small-text {
    font-size: .9em;
    font-style: italic;
  }
}
@media only screen and (max-width: 1068px) {
  #productInfo {
    .bg-col {
      min-width: 150px;
      width: 10%;
    }

    .fg {
      width: calc(min(1440px, 95%));
      margin-left: 5%;
    }
  }
}

@media only screen and (max-width: 747px) {
  #productInfo {
    .left, right {
      flex-basis: 100%;
    }

    .card {
      border-radius: 20px;
    }

    .left .img-list {
      grid-template-columns: repeat(4, 1fr);

      img {
        border-radius: 15px;
      }
    }

    div.section {
      padding-bottom: 1em;
    }
  }
}

@media only screen and (max-width: 675px) {
  #productInfo {
    .bg-col {
      width: 100%;
      height: 5em;
    }

    .right {
      flex-basis: 100%;
      margin: 0;
    }
  }
}
</style>