import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import TheBearChaptersApp from '../views/AppRedirects/TheBearChaptersApp.vue'
import DeviceCompatibility from '../views/LegalPages/DeviceCompatibility.vue'
import ThankYou from "../views/ShopPages/ThankYou.vue"
import PrivacyPolicy from "../views/LegalPages/PrivacyPolicy.vue"
import Shipping from "../views/LegalPages/Shipping.vue"
import ReturnsAndRefunds from "../views/LegalPages/ReturnsAndRefunds.vue"
import TermsOfServices from "../views/ShopPages/TermsOfServices.vue";
import Shop from "@/views/ShopPages/Shop.vue";
import ProductPage from "@/views/ShopPages/ProductPage.vue";
import CompanionApp from "@/views/CompanionApp";
import Links from "@/views/Links"
import ProductReviewPage from "@/views/ShopPages/ProductReviewPage.vue";
import HiddenLP from "@/views/LandingPages/HiddenLP.vue";
import PostcardDetails from "@/views/LandingPages/PostcardDetails.vue";
import HiddenAppRedirect from "@/views/AppRedirects/hidden-app-redirect.vue";
import NewsletterSignup from "@/views/LandingPages/NewsletterSignup.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },{
     path: '/home', redirect: '/'
  },
    {
    path: '/the-bear-chapters-app',
    name: 'The Bear Chapters - App',
    component: TheBearChaptersApp
  },
    {
    path: '/links',
    name: 'Links',
    component: Links
  },
    {
    path: '/shop',
    name: 'shop',
    component: Shop,
      props: route => ({filter : route.query.filter})
  },
    {
    path: '/companion-apps',
    name: 'companion-apps',
    component: CompanionApp
  },
  {
    path: "/product/:productName",
    name: 'product',
    component: ProductPage
  },
  {
    path: '/compatibility',
    name: 'DeviceCompatibility',
    component: DeviceCompatibility
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: ThankYou
  },
    {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
      props: route => ({policy : route.query.policy})
  },
  {
    path: '/shipping',
    name: 'Shipping',
    component: Shipping
  },
  {
    path: '/returns',
    name: 'ReturnsAndRefunds',
    component: ReturnsAndRefunds
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfServices',
    component: TermsOfServices
  },
  {
    path: '/product-review',
    name: 'ProductReviewPage',
    component: ProductReviewPage
  },
  {
    path: '/tales-from-the-trails-app',
    redirect: '/hidden-ar-postcards'
  },
  {
    path: '/hidden-ar-postcards',
    name: 'HiddenARPostcards',
    component: HiddenLP
  },
    {
    path: '/tbwttnl',
    redirect: '/the-bear-chapters-app'
  },
  {
    path: '/hidden-app-redirect',
    name: "HiddenAppRedirect",
    component: HiddenAppRedirect
  },
  {
    path: '/hidden-ar-postcards/:cardSlug',
    name: 'postcardDetails',
    component: PostcardDetails
  },
  {
    path: '/newsletter-signup',
    name: 'Newsletter Signup',
    component: NewsletterSignup
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return {
          el: to.hash
        }
      } else {
        return {top: 0 }
      }
    }
  }
})

export default router
