import { createStore } from 'vuex'

const store = createStore({
  state: {
    currentCurrencyIndex: 0,
    currencies: ["CAD"],
    products: [
      {
        itemId: "RBS01",
        slug: "the-bear-who-touched-the-northern-lights",
        tags: ["featured", "interactive-story"],
        productId: 7644979298462,
        info: {
          projectName: "The Bear Who Touched The Northern Lights",
          subtitle: "a choose-your-adventure story puzzle",
          highlight: 'Best for kids 4 years old and up.',
          description: "The Bear Who Touched The Northern Lights is an interactive story puzzle for little humans! Part puzzle, part animated story, with a dash of choose-your-own-adventure and a lot of Augmented Reality fun!\n \nThe puzzle is made of 16 pieces, each illustrated to represent a chapter in the journey of a little polar bear who dreams of playing with the Northern Lights. Once the puzzle is completed, the story visually emerges…but that’s not all! Using our companion app (on a phone or tablet), kids can scan each puzzle piece and listen and watch the story come to life, with animations and narration in Augmented Reality. There is also a choose-your-own-adventure aspect to this puzzle, as some pieces are optional, and others come in 2 versions, depicting different paths our little bear could take.",
          oneLiner: 'Part puzzle, part animated story, with a dash of choose-your-own-adventure and a lot of Augmented Reality fun - this story puzzle is will dazzle any kid!',
          price: [
            {
              value: 36,
              currency: "CAD"
            }
          ],
          details: ["Choose-your-own adventure: up to 9 key moments where choices can be made, which impacts the later story. ", "In the box: 1 play guide and 16 connecting story cards ( 4 swappable cards, 2 optional cards & 1 start card and 9 milestone cards)",
            "Requires a device supporting AR to activate the cards and play through the story. Most modern devices are supported. Check our <a href='/compatibility' class='details-link'> device compatibility</a> page for details.",
            "Can be played without parental guidance (except on first play to understand how to use the app)",
            "Free companion app <a href='/the-bear-chapters-app' class='details-link'>The Bear Chapters</a> available on the App Store and Google Play Store"],
          dimensions: ["Box size: 5” x 5”",
            "puzzle size: 3.5’ x 2’ (The size of the puzzle slightly varies depending on what optional piece you choose to place)"
          ],
          productImages: [{
            src: "https://player.vimeo.com/video/774860236?h=4416748c36&title=0&byline=0&portrait=0&controls=0",
            path: "product-shot_09.jpg",
            load: "product-shot_09_loading.jpg",
            type: "video"
          },
            {
              path: "product-shot_01.jpg",
              load: "product-shot_01_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_02.jpg",
              load: "product-shot_02_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_03.jpg",
              load: "product-shot_03_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_04.jpg",
              load: "product-shot_04_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_05.jpg",
              load: "product-shot_05_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_06.jpg",
              load: "product-shot_06_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_07.jpg",
              load: "product-shot_07_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_08.jpg",
              load: "product-shot_08_loading.jpg",
              type: "img"
            }],
          thumbnailImgForProductPage: 0,
          thumbnailImg: 1,
          maxPerPurchase: 10
        },
        shippingOptions: [0, 1, 2],
        components: ['TBWTTNLConcept', "TBWTTNLCards"]
      },
      {
        itemId: "RBS02",
        slug: 'dancing-shadows-umbras-halloween-party',
        tags: ['interactive-story'],
        productId: 7653387272350,
        info: {
          projectName: "Dancing Shadows: Umbra's Halloween Party",
          subtitle: "an interactive shadow book",
          highlight: "Best for kids 4 to 6 years old.",
          oneLiner: 'Feed monsters, help a witch fly, brew potions... all with only a picture book and a flashlight!',
          description: 'Feed monsters, help a witch fly, brew potions... all with only a picture book and a flashlight!\n \n Dancing Shadows: Umbra’s Halloween Party is the perfect interactive book for kids who want to be part of the story they are reading! Custom-cut paper shapes (a broom, a scoop, a witch) - all relevant to the story, are attached between each pages. With a flashlight/phone light, readers can cast their shadows onto the book pages, unlocking fun scenes and creating spooky animations! \n \nThis book is really fun to read at night… or take camping!',
          price: [
            {
              value: 40,
              currency: "CAD"
            }
          ],
          details: [
            "We hand-bind this book. Small imperfections may occur as a result of this process.",
            "This book and its story were designed to be used with a flashlight (handheld or phone)",
            "Flashlight not included!"
          ],
          dimensions: ["This picture book is 8” x 10”."],
          productImages: [{
            path: "product-shot_02.jpg",
            load: "product-shot_02_loading.jpg",
            type: "img"
          }, {
            path: "product-shot_01.jpg",
            load: "product-shot_01_loading.jpg",
            type: "img"
          }, {
            path: "product-shot_03.jpg",
            load: "product-shot_03_loading.jpg",
            type: "img"
          }, {
            path: "product-shot_04.jpg",
            load: "product-shot_04_loading.jpg",
            type: "img"
          }, {
            path: "product-shot_05.jpg",
            load: "product-shot_05_loading.jpg",
            type: "img"
          }, {
            path: "product-shot_06.jpg",
            load: "product-shot_06_loading.jpg",
            type: "img"
          }],
          thumbnailImgForProductPage: 0,
          thumbnailImg: 0,
          maxPerPurchase: 10
        },
        shippingOptions: [0, 1, 2],
        components: []
      },
      {
        itemId: "RBS03a",
        slug: 'hidden-s1e1',
        tags: ["hidden-postcards", "tales-from-the-trails"],
        comingSoonText: '',
        productId: 7653388812446,
        info: {
          projectName: "Playing By The Ponds - AR Postcard",
          subtitle: "A postcard that comes to life in AR!",
          highlight: 'Part of the Tales From The Trails interactive postcard collection.',
          description: 'Who doesn\'t love postcards? We do! We love to use them to remind our loved ones that we think about them, even when we are deep on the trails! We also love adding interactivity to our products - so this is a postcard with an interactive twist! \n \nThis postcard’s illustration turns into a short animated film, revealed in Augmented Reality using our free companion app available on iOS and Android. There is a QR code on the back of the postcard so that you, and whoever you send the postcard to, can enjoy the story hidden in the illustration.\n \n Playing by the ponds is part of our Tales From The Trails postcards collection, all about telling short stories that happen on, and around the (mountain) trails.',
          oneLiner: '',
          price: [
            {
              value: 8,
              currency: "CAD"
            }
          ],
          details: ["Free companion app <a href='/tales-from-the-trails-app' class='details-link'>Hidden: AR Postcards</a> available on the App Store and Google Play Store",
            "Requires a device supporting AR to scan the cards and play through the story. Most modern devices are supported. Check our <a  href='/compatibility' class='details-link'> device compatibility</a> page for details.", "Designed in Squamish, BC and printed in Vancouver, BC"],
          dimensions: ["4.25” x 6”"],
          productImages: [
            {
              path: "product-shot_01.jpg",
              load: "product-shot_01_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_02.jpg",
              load: "product-shot_02_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_03.jpg",
              load: "product-shot_03_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_04.jpg",
              load: "product-shot_04_loading.jpg",
              type: "img"
            }
            ],
          thumbnailImgForProductPage: 0,
          thumbnailImg: 0,
          maxPerPurchase: 10
        },
        shippingOptions: [],
        components: []
      },
      {
        itemId: "RBS03b",
        slug: 'hidden-s1e2',
        tags: ["hidden-postcards", "tales-from-the-trails"],
        comingSoonText: '',
        productId: 7659359371422,
        info: {
          projectName: "Subalpine Joy - AR Postcard",
          subtitle: "A postcard that comes to life in AR!",
          highlight: 'Part of the Tales From The Trails interactive postcard collection.',
          oneLiner: '',
          description: 'Ski season is around the corner! Yay, we are excited! Send a postcard to someone you want to go shred with! \n \nThis is not just a postcard though - it is a postcard with an interactive twist! This postcard’s illustration turns into a short animated film, revealed in Augmented Reality using our free companion app available on iOS and Android. There is a QR code on the back of the postcard so that you, and whoever you send the postcard to, can enjoy the story hidden in the illustration.\n \nSubalpine Joy is part of our Tales From The Trails postcards collection, all about telling short stories that happen on, and around the (mountain) trails.',
          price: [
            {
              value: 8,
              currency: "CAD"
            }
          ],
          details: ["Free companion app <a href='/tales-from-the-trails-app' class='details-link'>Hidden: AR Postcards</a> available on the App Store and Google Play Store",
            "Requires a device supporting AR to scan the cards and play through the story. Most modern devices are supported. Check our <a href='/compatibility' class='details-link'> device compatibility</a> page for details.", "Designed in Squamish, BC and printed in Vancouver, BC"],
          dimensions: ["4.25” x 6”"],
          productImages: [
            {
              path: "product-shot_01.jpg",
              load: "product-shot_01_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_02.jpg",
              load: "product-shot_02_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_03.jpg",
              load: "product-shot_03_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_04.jpg",
              load: "product-shot_04_loading.jpg",
              type: "img"
            }
          ],
          thumbnailImgForProductPage: 0,
          thumbnailImg: 0,
          maxPerPurchase: 10
        },
        shippingOptions: [],
        components: []
      },
      {
        itemId: "RBS03c",
        slug: 'hidden-s2e1',
        tags: ["hidden-postcards", "draw-on-it", "featured"],
        comingSoonText: '',
        productId: 8074672537758,
        info: {
          projectName: "We don't like rain - AR Postcard",
          subtitle: "A postcard that you can draw on!",
          highlight: 'Part of the "Extra Drawing Needed" postcard collection.',
          oneLiner: 'A postcard that you can draw on??!! Yes, you heard right! And it even comes to life in AR!',
          description: 'Draw a shelter for the little raccoons and then scan the postcard using our free companion app Hidden: AR Postcards. The card will come to life in AR and you will be able to see how your drawing is helping (or not…) the raccoons from escaping the downpour! \n \n The backside of the postcard features a QR code for easy download of our companion app \'Hidden: AR Postcards\' necessary to scan the postcard. Available on iOS and Android.',
          price: [
            {
              value: 8,
              currency: "CAD"
            }
          ],
          details: ["Free companion app <a href='/tales-from-the-trails-app' class='details-link'>Hidden: AR Postcards</a> available on the App Store and Google Play Store",
            "Requires a device supporting AR to scan the cards and play through the story. Most modern devices are supported. Check our <a href='/compatibility' class='details-link'> device compatibility</a> page for details.", "Designed in Squamish, BC and printed in Vancouver, BC"],
          dimensions: ["4.25” x 6”"],
          productImages: [
            {
              path: "product-shot_01.jpg",
              load: "product-shot_01_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_02.jpg",
              load: "product-shot_02_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_03.jpg",
              load: "product-shot_03_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_04.jpg",
              load: "product-shot_04_loading.jpg",
              type: "img"
            }
          ],
          thumbnailImgForProductPage: 0,
          thumbnailImg: 0,
          maxPerPurchase: 10
        },
        shippingOptions: [],
        components: []
      },
      {
        itemId: "RBS03d",
        slug: 'hidden-s2e2',
        tags: ["hidden-postcards", "draw-on-it", "featured"],
        comingSoonText: '',
        productId: 8148907983006,
        info: {
          projectName: "Reading about Mushrooms - AR Postcard",
          subtitle: "A postcard that you can draw on!",
          highlight: 'Part of the "Extra Drawing Needed" postcard collection.',
          oneLiner: 'A postcard that you can draw on??!! Yes, you heard right! And it even comes to life in AR!',
          description: 'Draw something to protect the mouse and her book from the rain, then scan the postcard using our free companion app Hidden: AR Postcards. The card will come to life in AR and you will be able to see how your drawing is helping (or not…) the little mouse escape the downpour! \n \n The backside of the postcard features a QR code for easy download of our companion app \'Hidden: AR Postcards\' necessary to scan the postcard. Available on iOS and Android.',
          price: [
            {
              value: 8,
              currency: "CAD"
            }
          ],
          details: ["Free companion app <a href='/tales-from-the-trails-app' class='details-link'>Hidden: AR Postcards</a> available on the App Store and Google Play Store",
            "Requires a device supporting AR to scan the cards and play through the story. Most modern devices are supported. Check our <a href='/compatibility' class='details-link'> device compatibility</a> page for details.", "Designed in Squamish, BC and printed in Vancouver, BC"],
          dimensions: ["4.25” x 6”"],
          productImages: [
            {
              path: "product-shot_01.jpg",
              load: "product-shot_01_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_02.jpg",
              load: "product-shot_02_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_03.jpg",
              load: "product-shot_03_loading.jpg",
              type: "img"
            }, {
              path: "product-shot_04.jpg",
              load: "product-shot_04_loading.jpg",
              type: "img"
            }
          ],
          thumbnailImgForProductPage: 0,
          thumbnailImg: 0,
          maxPerPurchase: 10
        },
        shippingOptions: [],
        components: []
      }
    ],
    hidden: {
      collections: [
        {
          season: 1,
          name: 'Tales from the Trails'
        },
        {
          season: 2,
          name: 'Extra Drawing Needed'
        }],
      postcards: [
        {
          name: 'Playing by the Ponds',
          slug: 'playing-by-the-ponds',
          season: 1,
          episode: 1,
          shortDescription: "A short animated story inspired by visuals and mood of Japanese studio Ghibli’s films. Blow on your mic and wake up a mystical creature.",
          thumbnail: "h_s1e1_thumbnail.png",
          componentToOpen: "",
          credits: {
            art: 'Julie Puech',
            animation: 'Karl Kim',
            voiceOver: 'Julie Puech'
          },
          story: "This card’s story follows two young sisters as they find comfort in the wonders of nature - in the form of a magical snail that they try to wake up. ",
          interactions: {
            text: "This card places the player at the center of the story, by using the phone’s microphone to enact the actions of the sisters -  blowing on the little snail to wake him u It’s simple and just fun! ",
            imgs: []
          },
          galleryImgs: ["h_s1e1_gallery_1.png", "h_s1e1_gallery_2.png", "h_s1e1_gallery_3.png"]
        },
        {
          name: 'Subalpine Joy',
          slug: 'subalpine-joy',
          season: 1,
          episode: 2,
          shortDescription: "A tribute to spending time outdoors and enjoying backcountry skiing with friends and family. This card turns into a mini-skiing game!",
          thumbnail: "h_s1e2_thumbnail.png",
          componentToOpen: "",
          credits: {
            art: 'Julie Puech',
            animation: 'Karl Kim'
          },
          story: "This card’s visual and interactions are all about winter wonderland and how fun and freeing it is to enjoy time outdoors in the snow with friends, and this case, siblings.\n \n A race through the snow, focused on the terrain ahead, with the environment around you changing... until you get to a hut for the night, to warm up and rest and do it all over again!",
          interactions: {
            text: "With this interactive postcard, we wanted to explore using the physical movement of the postcard as a “controller”, influencing the movement of one of the characters on the card. \n \n By angling the postcard to the left, the sister dressed in yellow start to go up; and by angling the postcard the the right, she goes downhill. \n \n This interaction turns this card into a mini-game where the player has to react to the terrain by angling the card, making sure the character is in the right position based on the terrain ahead! ",
            imgs: ["h_s1e2_interactions_1.png", "h_s1e2_interactions_2.png"]
          },
          galleryImgs: ["h_s1e2_gallery_1.png", "h_s1e2_gallery_2.png", "h_s1e2_gallery_3.png", "h_s1e2_gallery_4.png", "h_s1e2_gallery_5.png"]
        },
        {
          name: 'We don\'t like rain',
          slug: 'we-dont-like-rain',
          season: 2,
          episode: 1,
          shortDescription: "Draw on this card to help raccoons shelter from the rain! A unique interactive postcard that you can customize to your liking!",
          thumbnail: "h_s2e1_thumbnail.png",
          componentToOpen: "",
          credits: {
            art: 'Julie Puech',
            animation: 'Karl Kim'
          },
          story: "This card transports you to a large flower field where a papa raccoon and his baby are trying to shelter from the incoming rain. \n \n The card visual invite you to draw something in the hand of the papa raccoon, something that can be used as an umbrella... ",
          interactions: {
            text: "Draw on the postcard itself something to shelter the raccoons from the rain! This card features different animations wether the raccoons are drenched by the rain or not. Don’t hesitate to play the card first without any drawing, and then let your inner artist out. \n \n Some examples of what you can draw can be seen below! ",
            imgs: ["h_s2e1_interactions_1.png"]
          },
          galleryImgs: ["h_s2e1_gallery_1.png", "h_s2e1_gallery_2.png", "h_s2e1_gallery_3.png", "h_s2e1_gallery_4.png"]
        },
        {
          name: 'Reading About Mushrooms',
          slug: 'reading-about-mushrooms',
          season: 2,
          episode: 1,
          shortDescription: "Draw on this card to keep the little mouse and her book dry!   ",
          thumbnail: "h_s2e2_thumbnail.png",
          componentToOpen: "",
          credits: {
            art: 'Julie Puech',
            animation: 'Karl Kim'
          },
          story: "This card shows a little mouse reading a book about mushrooms high up in a tree. As it starts to rain, she has two options: using her book as an umbrella or getting your help to give her something she can use to prevent getting wet.",
          interactions: {
            text: "Draw on the postcard itself something to shelter the mouse from the rain! This card features different animations wether the mouse stays dry or not. Don’t hesitate to play the card first without any drawing, and then let your inner artist out. \n \n Some examples of what you can draw can be seen below! ",
            imgs: ["h_s2e2_interactions_1.png"]
          },
          galleryImgs: ["h_s2e2_gallery_1.png", "h_s2e2_gallery_2.png"]
        }
      ]
    },
    isBannerOpen: true,
    shopifyUIClient: null,
    allowNewsletterSignIn: true
  },
  getters: {
    getHiddenCollections: (state) => {
      return state.hidden.collections;
    },
    getHiddenPostcards: (state) => {
      return state.hidden.postcards;
    },
    getHiddenPostcardBySlug: (state) => (slug) => {
      let index = -1;
      for (let i = 0; i < state.hidden.postcards.length; i++) {
        if (state.hidden.postcards[i].slug === slug) {
          index = i;
          break;
        }
      }
      if (index === -1) {
        return null;
      } else {
        return state.hidden.postcards[index];
      }
    },
    getProductInfoById: (state) => (id) => {
      let index = -1;
      for (let i = 0; i < state.products.length; i++) {
        if (state.products[i].itemId === id) {
          index = i;
          break;
        }
      }
      if (index === -1) {
        return null;
      } else {
        return state.products[index].info;
      }
    },
    getProductBySlug: (state) => (slug) => {
      let index = -1;
      for (let i = 0; i < state.products.length; i++) {
        if (state.products[i].slug === slug) {
          index = i;
          break;
        }
      }
      if (index === -1) {
        return null;
      } else {
        return state.products[index];
      }
    },
    getShippingOptions: (state) => {
      return state.shippingOptions;
    },
    getCurrentCurrencyIndex: (state) => {
      return state.currentCurrencyIndex;
    },
    getCurrentCurrency: (state) => {
      return state.currencies[state.currentCurrencyIndex];
    },
    getNbItemsInCart: (state) => {
      return state.cart.length;
    },
    getBannerState: (state) => {
      return state.isBannerOpen;
    },
    getAllProducts: (state) => {
      return state.products;
    },
    getFeaturedProducts: (state) => {
      let featuredProducts = [];
      state.products.forEach((product) => {
        if (product.tags.includes('featured')) {
          featuredProducts.push(product);
        }
      })
      return featuredProducts;
    }
  },
  mutations: {
    updateBannerState: (state, payload) => {
      state.isBannerOpen = payload;
    },
    setShopifyClient: (state, payload) => {
      state.shopifyUIClient = payload;
    }
  },
  actions: {
    createBuyButton: async ({state}, payload) => {

      var ui = ShopifyBuy.UI.init(state.shopifyUIClient);

      ui.createComponent('product', {
        id: payload,
        node: document.getElementById('atc_' + payload),
        options: {
          product: {
            contents: {
              img: false,
              title: false,
              price: false,
              quantity: true,
              quantityDecrement: true,
              quantityIncrement: true,
            },
            text: {
              button: 'Add to Cart'
            },
            styles: {
              button: {
                "font-family": "'Poppins', sans-serif",
                "font-weight": 700,
                "font-size": "17px",
                "line-height": "30.4px",
                "padding": "1rem 4rem",
                "border-radius": "20px",
                "border": "none",
                "text-align": "center",
                "margin": "0 0 1.5em 0 !important",
                "width": "100%",
                'background-color': '#2E4F9C',
                "color": "#F5F5EF",
                ':hover': {
                  'background-color': '#162D64'
                },
                ':focus': {
                  'background-color': '#162D64'
                }
              },
              quantityIncrement: {
                "width": "15%",
                "background-color": "#8BCFCF",
                "border-radius": "40px",
                "border": "none",
                "color": "#F5F5EF",
                "font-family": "'Poppins', sans-serif",
                "font-weight": 700,
                "font-size": "20px",
                "line-height": "30px !important",
              },
              quantityDecrement: {
                "width": "15%",
                "background-color": "#8BCFCF",
                "border-radius": "40px",
                "border": "none",
                "color": "#F5F5EF",
                "font-family": "'Poppins', sans-serif",
                "font-weight": 700,
                "font-size": "20px",
                "line-height": "30px !important",
              },
              quantityInput: {
                "font-family": "'Poppins', sans-serif",
                "font-weight": 700,
                "font-size": "20px",
                "width": "60%",
                "margin": "0 5% 0 5%",
                "background-color": "#F5F5EF",
                "border-radius": "40px",
                "border": "1px solid #4DAAAA !important",
                ":focus-visible": {
                  "border": "1px solid #2E4F9C !important",
                  "outline": 'none'
                }

              }
            }
          },
          toggle: {
            styles: {
              toggle: {
                'background-color': '#8BCFCF',
                "font-weight": 700,
                'border-radius': '20px 0 0  20px',
                "font-family": "'Poppins', sans-serif",
                "color": '#2E4F9C',
                ":hover": {
                  "background-color": "#4DAAAA"
                },
              },
              "iconPath": {
                "icon": '<svg xmlns="https://cdn.shopify.com/s/files/1/0565/6425/2845/files/bag.png?v=1620071971"/>',
                "fill": "#2E4F9C"
              }
            }
          },
          cart: {
            styles: {
              button: {
                "font-family": "'Poppins', sans-serif",
                "font-weight": 700,
                "font-size": "17px",
                "line-height": "30.4px",
                "padding": "1rem 4rem",
                "border-radius": "20px",
                "border": "none",
                "text-align": "center",
                "margin": "1em 0 1.5em 0 !important",
                "width": "100%",
                'background-color': '#2E4F9C',
                "color": "#F5F5EF",
                ':hover': {
                  'background-color': '#162D64'
                },
                ':focus': {
                  'background-color': '#162D64'
                }
              },
              cart: {
                'background-color': '#F5F5EF',
                'border-left': '10px solid #8BCFCF'
              },
              footer: {
                'background-color': '#F5F5EF',
              },
              "title": {
                "font-family": "'Poppins', sans-serif",
                "font-weight": 700,
                "color": "#2E4F9C"
              },
              "header": {},
              "lineItems": {
                "font-family": "'Karla', sans-serif",
              },
              "subtotalText": {
                "font-family": "'Karla', sans-serif",
                "font-size": "14px",
              },
              "subtotal": {
                "font-family": "'Karla', sans-serif",
                "font-size": "14px",
              },
              "notice": {
                "font-family": "'Karla', sans-serif",
                "font-size": "12px",
              },
              "currency": {
                "font-family": "'Karla', sans-serif",
              },
              "close": {
                "color": "#2E4F9C",
                ":hover": {
                  "color": "#162D64"
                }
              },
              "empty": {
                "font-family": "'Karla', sans-serif",
              },
              "noteDescription": {
                "font-family": "'Karla', sans-serif",
              },
              "discountText": {
                "font-family": "'Karla', sans-serif",
              },
              "discountIcon": {
                "fill": "#2E4F9C"
              },
              "discountAmount": {
                "font-family": "'Karla', sans-serif",
              },
            },
            text: {
              "title": "Cart",
              "total": "Subtotal",
              "empty": "Your cart is empty.",
              "notice": "Shipping and discount codes will be added at checkout.",
              "button": "Checkout"
            }

          }
        },
      });
    },
    addNewsletterSubscriber: async ({state}, email) => {
      if (state.allowNewsletterSignIn) {
        state.allowNewsletterSignIn = false;
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: email
        }
        const response = await fetch(process.env.VUE_APP_NEWSLETTER_ENDPOINT, options);
        state.allowNewsletterSignIn = true;
        var body = await response.json();
        return {success:body.success, error: body.error};
      } else {
        return {success: false, error: 'Not Allowed.'}
      }
    }
  }
})

export default store;
